@import '../../styles/import.scss';

.footer-ui {
    margin-top: auto;

    .security-text {
        background-color: $blue;
        padding: 30px 0;
        display: flex;
        justify-content: space-evenly;
        font-size: 16px;
        color: #ffffff;
        @media (max-width: $desktop) {
            font-size: 14px;
        }
        @media (max-width: $mobile) {
            display: block;
            background-color: #F4F4F4;
            border-top: 13px solid $blue;
            padding: 19px 7px 19px 17px;
            color: #000000;
        }
    }
    .security-point {
        @media (max-width: $mobile) {
            margin: 9px 0;
            span {
                display: block;
                margin-left: 26px;
            }
        }
    }
    .checked {
        vertical-align: middle;
        margin-right: 15px;
        @media (max-width: $desktop) {
            width: 14px;
            margin-right: 8px;
        }
        @media (max-width: $mobile) {
            display: none;
        }
    }
    .checked-grey {
        display: none;
        @media (max-width: $mobile) {
            display: inline-block;
            width: 18px;
            vertical-align: middle;
            // margin-right: 8px;
            float: left;
        }
    }

    .footer-links {
        padding: 14px 5px 25px;
        text-align: center;
        @media (min-width: $tablet) {
            padding: 40px 15px 40px;
        }
    }
    .footer-links-block {
        display: inline-block;
        font-size: 12px;
        @media (min-width: $tablet) {
            font-size: 16px;
        }
        a {
            white-space: nowrap;
            color: #a6a6a6;
            text-decoration: none;
            &:hover {
                color: #767676
            }
        }
    }
    .separator {
        display: inline-block;
        color: #a6a6a6;
        margin: 0 18px;
        @media (min-width: $tablet) {
            margin: 0 22px;
        }
    }
}