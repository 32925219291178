// Plece global variables and mixins here

$blue: #0099DE;
$yellow: #FFE700;
$error-color: #e86c60;

// Breakpoints
$mobile: 768px;
$tablet: 769px;
$tablet-max: 1024px;
$desktop: 1025px;
$desktop-wide: 1441px;