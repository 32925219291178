* {
    font-family: 'Roboto', sans-serif;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
