.input-ui {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
        font-size: .75em;
        display: flex;
        position: relative;
        align-items: center;
        white-space: nowrap;
    }
    .input {
        border: 2px solid #0099e1;
        box-shadow: #0099e1;
        box-shadow: 0px 0px 5px 0px rgba(0,153,225,0.75);
        box-sizing: border-box;
        width: 100%;
        margin: 15px 0 0 0;
        padding: 8px;
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        &.error {
            color: #e86c60;
            border: 2px solid #e86c60;
            box-shadow: #e86c60;
            box-shadow: 0px 0px 5px 0px rgba(232, 108, 96, .75);
            background: #fce9e7;
        }
    }
    .error-message {
        display: flex;
        align-items: center;
        width: 100%;
        color: #e86c60;
        font-size: .8em;
        height: 25px;
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }
    .input::-webkit-outer-spin-button,
    .input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .input[type=number] {
        -moz-appearance: textfield;
    }
}