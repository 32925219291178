@import '../../styles/import.scss';

.user-details-form-ui {
    padding: 0 10px;

    .title {
        font-size: 25px;
        font-weight: 300;
        line-height: normal;
        margin: 30px 0 10px;
    }
    .user-data-form {
        max-width: 700px;
        margin: 15px auto ;
        display: block;
    }
    .submit-request-btn {
        max-width: 250px;
        float: right;
        margin: 30px 0;
    }
    .form-error {
        margin-top: 20px;
        color: $error-color;
    }
}