@import '../../styles/import.scss';

.success-message-ui {
    margin: 30px 0;
    .page-container {
        max-width: 566px;
        padding: 15px;
        margin: 0 auto;
    }
    .message-container {
        padding: 76px 68px 40px;
        border: solid 3px #0398dd;
        @media (max-width: $mobile) {
            padding: 40px 20px;
        }
    }
    .checked-img {
        width: 73px;
        height: 60px;
        margin: 0 auto;
        display: block;
    }
    .title {
        margin: 52px 22px;
        font-size: 23px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.41px;
        text-align: center;
    }
    .message-text {
        font-size: 16px;
        font-weight: normal;
        text-align: center;
    }
    .go-home-btn {
        margin-top: 14px;
    }
}