.progress-bar-ui {
    background: #a6a6a6;
    height: 20px;
    width: 100%;
    border-radius: 2em;
    position: relative;
    overflow: hidden;
    margin: 15px 0;
    &.size-100 {
        background: #0099DE;
        .animation {
            display: none;
        }
    }
    .animation {
        background: url('./image/progress.gif');
        height: 20px;
    }    
}