.search-base-ui {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    @media (max-width: 600px) {
        padding: 0 20px;
    }
    .outer-container {
        width: 566px;
        @media (max-width: 600px) {
            width: 100%;
        }
        .headline {
            font-size: 1.5em;
            font-weight: 300;
            text-align: center;
        }
    }
    .inner-container {
        background: #f4f4f4;
        padding: 20px 30px;
        .button-side {
            display: flex;
        }
    }
}