.textarea-ui {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
        font-size: .75em;
    }
    .textarea {
        min-height: 100px;
        border: 2px solid #0099e1;
        box-shadow: #0099e1;
        box-shadow: 0px 0px 5px 0px rgba(0,153,225,0.75);
        box-sizing: border-box;
        width: 100%;
        margin: 15px 0;
        padding: 8px 20px;
        font-size: 1.3em;
        text-align: center;
    }
}