.search-amount-ui {
    .input {
        width: 100px;
    }
    .button-side {
        margin: 0 -5px;
        .button-ui {
            margin: 0 5px;
            font-size: .9em;
        }
    }
}