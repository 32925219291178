@import '../../styles/import.scss';

.products-list-ui {
    max-width: 799px;
    padding: 0 10px;
    margin: 15px auto;

    .title {
        font-size: 25px;
        font-weight: 300;
        line-height: normal;
        text-align: center;
        margin: 35px 0 15px;
    }
    .table-container {
        overflow-x: auto;
    }
    .product-table {
        width: 100%;
        min-width: 360px;
        font-size: 17px;
        border-collapse:separate;
        border-spacing:0 5px;
        table-layout: fixed;
    }
    .products-header {
        font-weight: bold;
        white-space: nowrap;
        color: #4A4A4A;
    }
    .info {
        margin: -10px 0 5px 5px;
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
    .product-row {
        background-color: #f4f4f4;
        td {
            vertical-align: middle;
            height: 88px;
            padding: 3px;
            box-sizing: border-box;
        }
        &.express-product{
            background-color: #f2fafd;
        }
    }

    .espress-col {
        text-align: center;
    }
    .quantity {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }
    .grey {
        color: #939393;
    }
    .product-img {
        max-width: 83px;
        max-height: 83px;
        margin: 0 auto;
        display: block;
    }
    td, th {
        &.product-details {
            // width: 350px;
            font-size: 17px;
            line-height: 1.65;
            padding-left: 8px;
            color: #4A4A4A;
        }
    }
    .brand {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: bold;
    }
    .product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .price {
        font-size: 24px;
        font-weight: 500;
        white-space: nowrap;
    }
    .trash-icon {
        width: 30px;
        height: 39px;
        display: block;
        float: right;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .buttons-container {
        text-align: right;
        padding: 30px 0;
    }
    .further-article-btn {
        width: auto;
        padding: 0 40px;
        margin-bottom: 15px;
    }
    .show-user-data-btn {
        width: auto;
        padding: 0 40px;
        margin-left: 20px;
    }

    .quantity-col {
        width: 90px;
    }
    .img-col {
        width: 90px;
    }
    .product-details {

    }
    .remove-col {
        width: 67px;
    }

}