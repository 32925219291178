@import '../../styles/import.scss';

.login-ui {
    .page-container {
        max-width: 566px;
        padding: 15px;
        margin: 0 auto;
        color: #666666;
    }
    .grey-container {
        background-color: #f4f4f4;
        padding: 19px 26px;
    }
    .error {
        color: $error-color;
    }
}