@import '../../styles/import.scss';

.button-ui {
    width: 100%;
    height: 50px;
    font-size: 1em;
    color: #ffffff;
    background-color: $blue;
    text-overflow: ellipsis;
    border: none;
    position: relative;
    cursor: pointer;
    &.yellow {
        background-color: $yellow;
        color: #000000;
    }
    &.grey {
        background-color: #E4E4E4;
        color: #000000;
    }
    &.size-large {
        height: 73px;
        font-size: 21px;
    }
    &:active:not([disabled]), &:hover:not([disabled]) {
        opacity: 0.85;
    }
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

