

.language-list-item {
  padding-bottom: .5rem;
  list-style-type: none;

  .language-list-item__button {
    cursor: pointer;
    padding: 0;
    border: 0;
    color: #0098dd;
    background-color: transparent;
    font-size: .8rem;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  &--selected .language-list-item__button{
   color: #bcbcbc;
   cursor: auto;
   &:hover {
    text-decoration: none;
  }
  }
}
