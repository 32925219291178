@import '../../styles/import.scss';

.header-ui {

    .header-content {
        margin: 25px auto 20px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        @media (max-width: $mobile) {
            margin: 15px auto 5px;
        }
        @media (min-width: $mobile) {
            max-width: 990px;
        }
        @media (min-width: $desktop) {
            max-width: 1150px;
        }
        @media (min-width: $desktop-wide) {
            max-width: 1405px;
        }
    }

    .under-header-line {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        width: calc(100vw - 30px);
        background-color: #0098dd;
        z-index: 3;
        height: 28px;
        @media (max-width: $mobile) {
            display: none;
        }
    }

    .poligon {
        background-color: $blue;
        position: absolute;
        top: 0;
        left: 0;
        height: 42px;
        display: none;

        @media (min-width: $desktop) {
            width: calc((100vw - 900px)/2 + 50px);
            display: block;
        }
        @media (min-width: $desktop-wide) {
            width: calc((100vw - 1150px)/2 + 50px);
        }

        &:after {
            clip-path: polygon(0 0,100% 0,100% 28%,88% 104%,0% 99%);
            content: '';
            background-color: $blue;
            position: absolute;
            top: 0;
            right: -13px;
            width: 100px;
            height: 42px;
            z-index: -1;
        }
    }

    .logo {
        @media (max-width: $mobile) {
            width: 130px;
        }
    }

    .menu-link {
        font-size: 16px;
        line-height: 1.2;
        display: inline-block;
        color: #000000;
        text-decoration: none;
        text-align: center;
        &:hover, &:active {
            color: #000000;
        }
    }
    .menu-icon-block {

    }
    .menu-icon {

    }
    .menu-link-text {
        margin-top: 5px;
        white-space: nowrap;
    }

    .logo-text {
        font-size: 13px;
        line-height: 18px;
        border-radius: 11px;
        color: #a6a6a6;
        border: 2px solid $blue;
        display: inline-block;
        padding: 0 16px 0 18px;
        position: relative;
        vertical-align: top;
        margin: 6px 0 0 15px;
        @media (max-width: $mobile) {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            background-color: $blue;
            border-radius: 100%;
            width: 12px;
            height: 12px;
            left: 3px;
            top: 3px;
        }

    }

    .country-language-wrapper {
      color: #bcbcbc;
      display: inline-block;
      margin-right: 40px;

      .country-language-item {
        display: inline-block;
        position: relative;

        .country-language-button {
          cursor: pointer;
          padding: 0;
          border: 0;
          background: 0 0;
          color: #bcbcbc;
          background-color: transparent;

          &:hover {
            color: #0098dd;
            text-decoration: underline;
          }
        }
      }


  }

    .breadcrumbs {
        margin: 41px auto;
        padding: 0 15px;
        @media (max-width: $mobile) {
            margin: 15px auto;
        }
        @media (min-width: $mobile) {
            max-width: 990px;
        }
        @media (min-width: $desktop) {
            max-width: 1150px;
        }
        @media (min-width: $desktop-wide) {
            max-width: 1405px;
        }
    }

    @media (min-width: $tablet) {
        .breadcrumb {
            display: inline-block;
            font-size: 13px;
            line-height: 31px;
            padding: 0 10px;
            border: 1px solid #666666;
            color: #000000;
            text-decoration: none;
        }
        a.breadcrumb:hover {
            text-decoration: none;
            color: $blue;
            border: 1px solid $blue;
        }
        .arrow-right {
            display: inline-block;
            margin: 0 15px;
            vertical-align: middle;
        }
    }
    @media (max-width: $mobile) {
        .desktop-only {
            display: none !important;
        }
        .breadcrumb {
            display: inline-block;
            font-size: 11px;
            line-height: 14px;
            color: #000000;
            text-decoration: none;
        }
        a.breadcrumb:hover {
            text-decoration: none;
            color: $blue;
        }
        .arrow-right {
            width: 5px;
            display: inline-block;
            margin: 0 9px;
            vertical-align: middle;
        }
    }
}
