.language-layer {
  position: absolute;
  top: calc(100% + 1.25rem);
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  padding: 1rem 1.5rem;
  border: 1px solid #bcbcbc;
  background: #fff;
  z-index: 5;
  animation: slideDown .2s ease-out;

  &:before {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: "";
    top: -11px;
    height: 20px;
    width: 20px;
    background: #fff;
    transform: rotate(225deg);
    border-bottom: inherit;
    border-right: inherit;
  }

  @keyframes slideDown {
    0% {
      margin-top: -10px;
    }

    to {
      margin-top: 0;
    }
  }

  .language-list {
    padding-left: 0;
    list-style-type: none;
    column-count: 1;
  }
}
