.product-details-ui {
    display: flex;
    font-size: .85em;
    padding: 10px;
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
    .details {
        display: flex;
        flex-direction: column;
        width: calc(100% - 150px);
        @media (max-width: 500px) {
            width: 100%;
            text-align: center;
        }
        .brand {
            font-weight: bold;
            display: inline-block;
            margin: 0 10px 0 0;
        }
        p {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .amount {
        padding-left: 10px;
        width: 40px;
    }
    .url {
        padding-left: 10px;
        white-space: nowrap;
        width: 110px;
    }
    .amount, .url, .image {
        @media (max-width: 500px) {
            margin: 10px 0;
            padding: 0px;
            text-align: center;
        }
    }
    img {
        max-height: 100%;
    }
    p {
        margin: 0 0 3px 0;
    }
    a {
        color: #0099DE;
        text-decoration: none;
    }
    span {
        color: #666;
    }
    .marketplace {
        top: 2px;
        background: #0098dd;
        color: #fff;
        font-size: .7em;
        width: 15px;
        height: 15px;
        position: relative;
        display: inline-block;
        border-radius: 3px;
        &:after {
            content: 'M';
            text-align: center;
            position: absolute;
            width: 15px;
            height: 15px;
            top: 2px;
            left: 0px;
        }
    }
    .content {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 83px);
        margin-left: 15px;
        @media (max-width: 500px) {
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin: 0px;
        }
    }
    .image {
        width: 68px;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.loading {
        .image {
            position: relative;
            &:after {
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                background: linear-gradient(45deg, #c7c6c6, #e7e6e6, #c7c6c6);
                background-size: 200% 200%;
                background-position: 0% 100%;
                animation-name: glowAnimation;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-direction: alternate;
            }
            @keyframes glowAnimation {
                from {
                    background-position: 0% 100%;
                }
                to {
                    background-position: 100% 0%;
                }
            }
        }
    }
}