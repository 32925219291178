.result-form-ui {
    .success {
        background: url('../../icons/success.svg') no-repeat center;
        width: 50px;
        height: 30px;
        margin: 10px auto;
    }
    .message {
        font-size: .85em;
        padding: 10px 20px;
        margin: 0 0 10px 0;
        color: #292929;
        text-align: center;
        position: relative;
        &:after {
            position: absolute;
            left: 0px;
            content: '';
            height: 1px;
            background: #a6a6a6;
            transition: all 0.5s linear;
            width: 0;
            bottom: 0px;
            animation: 1s ease-out 0s 1 normal forwards slideInFromLeft;
        }
    }
    @keyframes slideInFromLeft {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
    }
    .button-side {
        margin: 0 -5px;
        .button-ui {
            margin: 0 5px;
            font-size: .9em;
            @media (max-width: 500px) {
                font-size: .8em;
            }
        }
    }
}